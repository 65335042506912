
import { defineComponent, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "underwriters-table-component",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      imageRoute: `${process.env.VUE_APP_BASE_URL}/logo/underwriter/`,
    };
  },
  setup() {
    const {
      truncate,
      reinitialization,
      useReusableNavigation,
      capitalize,
      isEmptyArray,
      displayDatatable,
    } = ResusableFunctions();

    const tableHeader = ref([
      {
        columnName: "Underwriter Logo",
        columnLabel: "logo",
        sortEnabled: true,
      },
      {
        columnName: "Year of Establishment",
        columnLabel: "yearOfEstablishment",
        sortEnabled: true,
      },
      {
        columnName: "Policy Count",
        columnLabel: "policyCount",
        sortEnabled: true,
      },
      {
        columnName: "Actions",
        columnLabel: "actions",
        sortEnabled: false,
      },
    ]);

    const underwriters = ref([
      {
        id: "",
        logo: "",
        name: "",
        policyCount: 0,
        publicId: "",
        yearOfEstablishment: 1900,
      },
    ]);

    // Function to get underwriters
    const getUnderwriters = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_UNDERWRITERS_LIST_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    // Function to delete banner
    const deleteUnderwriter = async (id: string) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.SUPERADMIN_UNDERWRITER_ROUTE}/${id}`)
          .then(({ data }) => {
            getUnderwriters();
            variables.toastAlert(data.data.message, "success");
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    // Call the getUnderwriters function when the component is mounted
    onMounted(() => {
      getUnderwriters();

      if (!isEmptyArray(underwriters.value)) {
        displayDatatable(["basicExample"]);
      }
    });

    return {
      tableHeader,
      underwriters,
      deleteUnderwriter,
      truncate,
      useReusableNavigation,
      reinitialization,
      capitalize,
    };
  },
});
