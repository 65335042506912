
import { defineComponent, onMounted } from "vue";
import UnderWritersTable from "@/components/admin/UnderWritersTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "underwriters-list",
  components: {
    UnderWritersTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Underwriters", ["Users"]);
    });
  },
  created() {
    //Set page title
    document.title = "Underwriters | " + process.env.VUE_APP_TITLE;
  },
});
